<template>
    <div class="grid justify-content-between">
                <div class="col-12 md:col-2" style="margin-top:-1.5rem;">
                    <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                        <h4 class="font-medium text-3xl text-900">Ubicación</h4>
                    </div>
                </div>

                <div class="col-12 md:col-8">
                    <div class="grid text-center md:text-left">
                        <div class="col-12 md:col-6">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Dirección</h4>
                            Carretera Nacional, 
                            El Tigre – Pariagúan, 
                            Sector El Oasis, 
                            Municipio Simón Rodríguez. 
                            Estado Anzoátegui. 
                        </div>
                        <div class="col-12 md:col-4 mt-3 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Correo electrónico</h4>
                            info@petroservicios7.com.ve
                        </div>
                        <div class="col-12 md:col-4 mt-3 md:mt-0">
                            <h4 class="font-medium text-2xl line-height-3 mb-3 text-900">Teléfono</h4>
                            (0283) 514.84.01
                        </div>        
                    </div>
                </div>
    </div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
    methods: {
    },
}
</script>

<style>

</style>